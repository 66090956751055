import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
const Bannersec = () => {
    return (

        <div className='container-fluid p-0'>
            <Carousel indicators={false} interval={null} slide={true}>
                <Carousel.Item>

                    <div className='col-lg-12'>
                        <div className='banner-carousel1'></div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>

                    <div className='col-lg-12'>
                        <div className='banner-carousel2'></div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>

                    <div className='col-lg-12'>
                        <div className='banner-carousel3'></div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>

                    <div className='col-lg-12'>
                        <div className='banner-carousel4'></div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>

                    <div className='col-lg-12'>
                        <div className='banner-carousel5'></div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>

                    <div className='col-lg-12'>
                        <div className='banner-carousel6'></div>
                    </div>

                </Carousel.Item>
            </Carousel>
        </div>



    )
}

export default Bannersec