import React from "react";

import "./history.css";
import { FaRegCalendar } from "react-icons/fa";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { useTranslation } from 'react-i18next'

const History = () => {

  const { t } = useTranslation()

  return (
    <div className="histpry-page">
      <section className="inner-banner-history"> 
        <div className="container">
          <div className="inner-bannerimg"></div>
        </div>
      </section>
      <div className="line-null mb-5">
        <div className="line mt-5"></div>
        <div className="top-line">{t('வரலாறு')} </div>
      </div>
      <div className="history-carousel">
        <section>
          <div class="container">

            <div class="row" >
              <div class="history-wrapper">
                <div class="title-wrap text-center one-of-two">
                </div>
                
                <div class="timeline-box one-of-two">
                <img className='history-images' loading="lazy" src={process.env.PUBLIC_URL + '/images/history-6.jpg'} alt="image" />
                  <div class="content">
                    <h3 class="h4 mb-2 mb-md-3">{t('வரலாறுவிளக்கம்1')}</h3>
                    <p class="mb-0">{t('வரலாறுஉள்ளடக்கம்1')}</p>
                  </div>
                  <div class="year"> 2023</div>
                </div>

                <div class="timeline-box one-of-two">
                <img className='history-images' loading="lazy" src={process.env.PUBLIC_URL + '/images/history-5.jpg'} alt="image" />
                  <div class="content">
                    <h3 class="h4 mb-2 mb-md-3">{t('வரலாறுவிளக்கம்2')}</h3>
                    <p class="mb-0">{t('வரலாறுஉள்ளடக்கம்2')}.</p>
                  </div>
                  <div class="year">2023</div>
                </div>
                <div class="timeline-box one-of-two">
                <img className='history-images' loading="lazy" src={process.env.PUBLIC_URL + '/images/history-4.jpg'} alt="image" />
                  <div class="content">
                    <h3 class="h4 mb-2 mb-md-3">{t('வரலாறுவிளக்கம்3')}</h3>
                    <p class="mb-0">{t('வரலாறுஉள்ளடக்கம்3')}</p>
                  </div>
                  <div class="year">2023</div>
                </div>
                <div class="timeline-box one-of-two">
                <img className='history-images' loading="lazy" src={process.env.PUBLIC_URL + '/images/history-3.jpg'} alt="image" />
                  <div class="content">
                    <h3 class="h4 mb-2 mb-md-3">{t('வரலாறுவிளக்கம்4')}</h3>
                    <p class="mb-0">{t('வரலாறுஉள்ளடக்கம்4')}</p>
                  </div>
                  <div class="year">2023</div>
                </div>
                <div class="timeline-box one-of-two">
                <img className='history-images' loading="lazy" src={process.env.PUBLIC_URL + '/images/history-2.jpg'} alt="image" />
                  <div class="content">
                    <h3 class="h4 mb-2 mb-md-3">{t('வரலாறுவிளக்கம்5')}</h3>
                    <p class="mb-0">{t('வரலாறுஉள்ளடக்கம்5')}</p>
                  </div>
                  <div class="year">2023</div>
                </div>
                <div class="timeline-box one-of-two">
                <img className='history-images' loading="lazy" src={process.env.PUBLIC_URL + '/images/history-7.jpg'} alt="image" />
                  <div class="content">
                    <h3 class="h4 mb-2 mb-md-3">{t('வரலாறுவிளக்கம்6')}</h3>
                    <p class="mb-0">{t('வரலாறுஉள்ளடக்கம்6')}</p>
                  </div>
                  <div class="year">2023</div>
                </div>
                <div class="timeline-box one-of-two">
                <img className='history-images' loading="lazy" src={process.env.PUBLIC_URL + '/images/history-1.jpg'} alt="image" />
                  <div class="content">
                    <h3 class="h4 mb-2 mb-md-3">{t('வரலாறுவிளக்கம்7')}</h3>
                    <p class="mb-0">{t('வரலாறுஉள்ளடக்கம்7')}</p>
                  </div>
                  <div class="year">2024</div>
                </div>
                <div class="timeline-box one-of-two">
                  <img className='history-images' loading="lazy" src={process.env.PUBLIC_URL + '/images/thalapathy.png'} alt="image" />
                  <div class="content">
                    <h3 class="h4 mb-2 mb-md-3">{t('வரலாறுவிளக்கம்8')}</h3>
                    <p class="mb-0">{t('வரலாறுஉள்ளடக்கம்8')}</p>
                  </div>
                  <div class="year">2024</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default History;
